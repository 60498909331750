import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideoAVM from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/VideoWPSAVM';
import YoutubeVideoAsus from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/VideoWPSAsus';
import YoutubeVideoNetgear from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/VideoWPSNetgear';
import YoutubeVideoLinksys from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/VideoWPSLinksys';
import YoutubeVideoDLink from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/VideoWPSDLink';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Wireless Protected Setup",
  "path": "/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "WLAN :: Plug & Play",
  "image": "../QI_SearchThumb_WLAN.png",
  "social": "/images/Search/QI_SearchThumb_WLAN.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Wireless_Connection_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Wireless Protected Setup' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='WLAN :: Plug & Play' image='/images/Search/TOC_Icons/Wiki_Tiles_QI-Wireless_Connection_white.png' twitter='/images/Search/TOC_Icons/Wiki_Tiles_QI-Wireless_Connection_white.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Herstellen_der_WLAN_Verbindung/WPS_Plug_and_Play/' locationFR='/fr/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/' crumbLabel="Wireless" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "connect-your-ip-camera-directly-to-your-local-wifi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#connect-your-ip-camera-directly-to-your-local-wifi",
        "aria-label": "connect your ip camera directly to your local wifi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connect your IP Camera directly to your local WiFi`}</h2>
    <p>{`If your WLAN router has the WPS function, you can simply connect your camera to your WLAN at the push of a button. The following explains the process for a number of routers - but the steps are also `}<strong parentName="p">{`transferable to other router models`}</strong>{`.`}</p>
    <YoutubeVideoAVM mdxType="YoutubeVideoAVM" />
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideoAsus mdxType="YoutubeVideoAsus" />
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideoNetgear mdxType="YoutubeVideoNetgear" />
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideoLinksys mdxType="YoutubeVideoLinksys" />
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideoDLink mdxType="YoutubeVideoDLink" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      